import React from "react";

import { Container, Subtitle, LinksList, ListItem } from "./styled";

const ItemAccordion = ({ content, links, className }) => {
  const [hidden, setHidden] = React.useState(true);
  const [height, setHeight] = React.useState(0);
  function handleHeigth() {
    const height = ((document.getElementById(className).clientHeight) * links.length) / 2.5;
    console.log(height)
    setHeight(height);
    setHidden(!hidden);
  }

  return (
    <Container
      hidden={hidden}
      height={height}
      className={className}
      key={content.id}
      id={className}
      onClick={() => handleHeigth()}
    >
      <div className="accordion__content">
        <h1 className="accordion__listTitle">{content.title}</h1>
        <div className="accordion__hidden">
          <LinksList>
            {links.map((link, i) => (
              <ListItem key={i}>
                <a href={link.href} target="blank">{link.title}</a>
              </ListItem>
            )
            )}
          </LinksList>
        </div>
      </div>
      <button className="accordion__btnClose">+</button>
    </Container>
  );
};

export default ItemAccordion;
