import React from "react";

import ItemAccordion from "./ItemAccordion";

import { Container } from "./styles";

const SectionAccordion = ({ content, links, activeBtnClass }) => {
  const [active, setActive] = React.useState(0);
  return (
    <Container>
      {content.map((element, i) => {
        return (
          <ItemAccordion
            key={element.id}
            content={element}
            links={(i === 0) ? links.legal : links.statements}
            className={`item-${element.id}`}
          />
        );
      })}
    </Container>
  );
};

export default SectionAccordion;
