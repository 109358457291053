import React from 'react';

import { Container } from './styles';

import Layout from '../../components/Layout';
import Jumbotron from '../../components/Jumbotron';
import SectionAccordion from "../../components/SectionAccordion";

import { content, links } from "../../content/pages/Corporation";

const CorporationPage: React.FC = () => {

  React.useEffect(() => {
    document.querySelector('body').scrollTo(0, 0)
  }, [])
  return (
    <>
      <Layout>
        <Container>
          <section className="bg-header-corporation"></section>
          <section className="container pos-relative">
            <Jumbotron
              className="jumbotron-contact"
              title="Governança Corporativa"
              text="Políticas, Condições e Demonstrações"
            />
            <SectionAccordion
              content={content}
              links={links}
              activeBtnClass="activeBtn"
            />
          </section>
        </Container>
      </Layout>
    </>
  );
};

export default CorporationPage;
