import styled from "styled-components";
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  h1.accordion__title {
    font-size: 26px;
    max-width: 700px;
    margin: 80px auto 0;
    padding: 0 30px;
    text-align: center;
    font-weight: 600;
    height: 100%;

    ${mediaQuery.desktop`
      margin: 150px auto 0;
      font-size: 40px;
    `}
  }
  a { 
    &:hover {
      color: #D4E32C;
    }
  }

  .accordion__headers {
    display: flex;
    justify-content: center;

    .accordion__headersBtn {
      margin: 50px 8px;
      font-size: 12px;
      padding: 8px 43px;
      color: #23797B;
      border: 2px solid transparent;
      border-radius: 3px;
      background: transparent;
      transition: .6s;
      font-weight: 600;

      ${mediaQuery.desktop`
        font-size: 16px;
        padding: 15px 43px;
      `}
    }
  }
`;

export const SectionTitle = styled.span`
`;
