import styled, { css } from "styled-components";
import { mediaQuery } from '../../../assets/styles/mixins';

export const Container = styled.article`
  width: 100%;
  height: 100%;
  max-width: 700px;
  font-size: 12px;
  padding: 30px;
  box-shadow: 0px 3px 6px #00000029;
  margin: 0px auto 20px;
  letter-spacing: 0.8px;
  display: flex;
  overflow: hidden;
  cursor: pointer;
  transition: 0.4s;

  ${mediaQuery.desktop`
     font-size: 16px;
  `}

  .accordion__content {
    max-width: 588px;
  }

  .accordion__listTitle {
    color: ${({ theme }) => theme.font.third};
    /* min-height: 56px; */
    font-weight: 600;
    font-size: 16px;
  }

  .accordion__btnClose {
    width: 30px;
    height: 30px;
    color: ${({ theme }) => theme.font.fourth};
    border: none;
    background: transparent;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    transition: 0.3s;
    transform: rotate(0deg);

    ${({ hidden }) =>
    !hidden &&
    css`
        transform: rotate(45deg);
      `}
  }

  .accordion__hidden {
    width: 100%;
    position: relative;
    transition: height 0.4s;
    overflow: hidden;
    height: ${({ hidden, height }) =>
    !hidden ? `${height}px` : "0px !important"};
  }
`;

export const Text = styled.p`
  width: 100%;
  position: absolute;
  color: ${({ theme }) => theme.font.primary};
  letter-spacing: 0.8px;
  font-size: 12px;
  display: flex;
  transition: 0.4s;
  padding-top: 5px;

  ${mediaQuery.desktop`
     font-size: 16px;
  `}
`;

export const LinksList = styled.ul`
  width: auto;
  height: auto;
`;

export const Subtitle = styled.span`
  width: auto;
  height: auto;
  margin: auto;
`;

export const ListItem = styled.li`
margin: 10px 0px;
a {
  color: #383838;
  }
`;
